<div
  class="test-skeleton-container text-center"
  *ngIf="(isFullLocationLoaded$ | async) === false || !locationData; else buildingDetails">
  <app-skeleton-page-header />
  <ngx-skeleton-loader [theme]="{ height: '130px' }" />
  <ngx-skeleton-loader [theme]="{ height: '300px' }" />
  <app-skeleton-table />
</div>
<ng-template #buildingDetails>
  <div class="test-content-container" *ngIf="locationData">
    <div>
      <app-dashboard-location-header [locationData]="locationData" />

      <app-building-info [locationData]="locationData" [buildingStats]="buildingStats" />

      <app-building-plan-visual-mode [locationData]="locationData" [buildingState]="buildingStats" />

      <app-building-plan-incidents
        [locationId]="locationData.id"
        *ngIf="locationData.status !== entityStatus.Archived" />
    </div>
    <!--  Uncomment in version 2.0-->
    <!--  <app-site-history-table *ngIf="logs" [logs]="logs"></app-site-history-table>-->
  </div>
</ng-template>
