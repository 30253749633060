<ng-container *ngIf="(isIncidentLoaded$ | async) === true; else loading">
  <app-page-header>
    <div class="mb-6">Incidents</div>
  </app-page-header>
  <mat-card class="card py-0">
    <mat-card-content class="p-0">
      <app-incidents-table-desktop-filters
        [filtersEnabled]="true"
        [resolvedIncidentsLoading]="resolvedIncidentsLoading" />
      <app-incidents-table
        [dataIsLoading]="resolvedIncidentsLoading"
        noDataText="All devices are working properly"
        [isNoDataBigSize]="true" />
    </mat-card-content>
  </mat-card>
</ng-container>
<ng-template #loading>
  <app-skeleton-table />
</ng-template>
