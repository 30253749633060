@if (incident) { @let firmware = incident.device['tags']?.['firmware'];
<div class="test-incident-details">
  <div class="pb-4">
    <app-page-header>Ticket №: {{ incident.incidentNumber }}</app-page-header>
    <app-breadcrumbs [routeNames]="['Ticket №: ' + incident.incidentNumber]" />
  </div>
  <mat-card class="test-ticket-info bg-white card mb-4">
    <mat-card-title class="card-title pb-2 text-sm">Ticket No: {{ incident.incidentNumber }}</mat-card-title>
    <mat-card-content class="p-0">
      <div class="flex flex-row items-center pb-3">
        <label class="input-main-label">Incident Status:</label>
        <div class="mr-6 mt-2.5">
          <app-incident-status [status]="incident.status" />
        </div>
      </div>
      <div class="test-fault-name flex flex-row items-center pb-3">
        <label class="input-main-label">Fault Name:</label>
        <div class="mr-6 mt-2.5 text-sm">{{ incident.fault.name }}</div>
      </div>
      <div class="test-fault-desc flex flex-row items-center pb-3">
        <label class="input-main-label">Fault Description:</label>
        <div class="mr-6 mt-2.5 text-sm">{{ incident.fault.description || '-' }}</div>
      </div>
    </mat-card-content>
  </mat-card>
  <mat-card class="test-device-info bg-white card mb-4" *ngIf="incident.device">
    <mat-card-title class="card-title pb-2 text-sm">Device Info</mat-card-title>
    <mat-card-content class="p-0">
      <div class="flex content-between">
        <div class="flex-grow">
          <div class="flex flex-row items-center pb-3">
            <label class="input-main-label">Device Name:</label>
            <a
              class="mr-6 mt-2.5 underline text-sm"
              [routerLink]="['/devices', incident.device.location.id, incident.device.id]">
              {{ incident.device.friendlyName || incident.device.name }}
            </a>
          </div>
          <div class="test-device-status flex flex-row items-center pb-3">
            <label class="input-main-label">Device Status:</label>
            <div class="mr-6 mt-2.5 text-sm">
              <app-entity-status [status]="incident.device.status"></app-entity-status>
            </div>
          </div>
          <div class="test-device-type flex flex-row items-center pb-3">
            <label class="input-main-label">Device type:</label>
            <div class="mr-6 mt-2.5 text-sm">{{ incident.device.deviceModelInformation?.deviceType }}</div>
          </div>
          <div class="test-manufacturer flex flex-row items-center pb-3">
            <label class="input-main-label">Manufacturer:</label>
            <div class="mr-6 mt-2.5 text-sm">{{ incident.device.deviceModelInformation?.make?.name }}</div>
          </div>
          <div class="test-device-model flex flex-row items-center pb-3">
            <label class="input-main-label">Model:</label>
            <div class="mr-6 mt-2.5 text-sm">{{ incident.device.deviceModelInformation?.name }}</div>
          </div>
          <div class="test-device-id flex flex-row items-center pb-3">
            <label class="input-main-label">Serial №:</label>
            <div class="mr-6 mt-2.5 text-sm">{{ incident.device.physicalDeviceId }}</div>
          </div>
          @if (firmware) {
          <div class="flex flex-row items-center pb-3">
            <label class="input-main-label">Firmware:</label>
            <div class="mr-6 mt-2.5 text-sm">{{ firmware }}</div>
          </div>
          }
          <div class="flex flex-row items-center pb-3">
            <label class="input-main-label">Location:</label>
            <div class="test-device-location mr-6 mt-2.5 text-sm">
              <a class="underline" [routerLink]="['/dashboard', incident.device.location.id]"
                >Building: {{ incident.device.location.friendlyName || incident.device.location.name }}</a
              >,
              <a
                class="underline"
                *ngIf="incident.device.spacePath"
                [routerLink]="['/dashboard', incident.device.location.id, incident.device.spacePath[0].id]"
                >Floor: {{ incident.device.spacePath[0].friendlyName }}</a
              >,
              <a
                class="underline"
                *ngIf="incident.device.spacePath"
                [routerLink]="[
                  '/dashboard',
                  incident.device.location.id,
                  incident.device.spacePath[0].id,
                  incident.device.spacePath[1].id
                ]"
                >Room {{ incident.device.spacePath[1].friendlyName }}</a
              >
            </div>
          </div>
          <div class="flex flex-row items-center pb-3">
            <label class="input-main-label">Total Device Incidents:</label>
            <div class="mr-6 mt-2.5 font-bold text-sm">No data in response</div>
          </div>
          <div class="flex flex-row items-center pb-3">
            <label class="input-main-label"></label>
            <div class="mr-6 mt-2.5">
              <a
                class="btn-green btn-medium"
                [routerLink]="['/devices', incident.device.location.id, incident.device.id]">
                <span>Show All Device Incidents</span>
              </a>
            </div>
          </div>
        </div>
        <div>
          <app-img-loader
            loader="skeleton"
            *ngIf="incident.device.deviceModelInformation && incident.device.deviceModelInformation.documentReference"
            altText="device"
            errorSrc="assets/icons/image-error.svg"
            [imgSrc]="
              'clientId/' +
              appService.currentClient +
              '/library/standardMake/' +
              incident.device.deviceModelInformation.make.id +
              '/standardModel/' +
              incident.device.deviceModelInformation.id +
              '/document/' +
              incident.device.deviceModelInformation.documentReference
            " />
          <img
            alt="Device Image"
            *ngIf="!incident.device.deviceModelInformation?.documentReference"
            src="assets/icons/image-placeholder.svg" />
        </div>
      </div>
    </mat-card-content>
  </mat-card>
  <mat-card class="test-notes bg-white card">
    <mat-card-title class="card-title-small flex flex-row justify-between border-b pb-3 text-sm">Notes </mat-card-title>

    <div class="py-4">
      <mat-form-field class="w-full input-main bg-opaque h-auto">
        <textarea
          matInput
          cdkTextareaAutosize
          #autosize="cdkTextareaAutosize"
          cdkAutosizeMinRows="1"
          cdkAutosizeMaxRows="5"
          placeholder="Add Comment..."
          [(ngModel)]="noteDescription"></textarea>
      </mat-form-field>
      <div *ngIf="noteDescription" class="mb-6 flex items-center">
        <button
          [disabled]="!noteDescription.trim().length || postNoteLoading"
          (click)="addNote()"
          class="btn-green btn-small mr-2">
          <mat-spinner *ngIf="postNoteLoading" diameter="20" color="primary" class="mr-1"></mat-spinner>
          Save
        </button>
        <button (click)="noteDescription = ''" class="px-4 py-2">Cancel</button>
      </div>
    </div>

    <div *ngFor="let note of notes; let i = index" class="note-list pb-4">
      <div class="header">
        <strong *ngIf="note.user">{{ note.user.lastName }} {{ note.user.firstName }}</strong>
        <span>{{ note.timeStamp | date : 'medium' }}</span>
      </div>
      <div class="body">
        {{ note.textValue }}
      </div>
    </div>
  </mat-card>
</div>
} @else {
<div class="test-skeleton-animation">
  <ngx-skeleton-loader [theme]="{ height: '100px' }" />
  <ngx-skeleton-loader [theme]="{ height: '250px' }" />
  <ngx-skeleton-loader [theme]="{ height: '300px' }" />
  <ngx-skeleton-loader [theme]="{ height: '300px' }" />
</div>
}
