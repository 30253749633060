import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { createDeviceModelChart, createIncidentStatusChart } from '@app-lib';
import { IncidentModel, IncidentStatus } from '@models';
import * as Highcharts from 'highcharts';
import exporting from 'highcharts/modules/exporting';

@Component({
  selector: 'app-incident-charts',
  templateUrl: './incident-charts.component.html',
  styles: [],
})
export class IncidentChartsComponent implements OnChanges, OnInit {
  @Input() incidents: IncidentModel[] | null = null;
  Highcharts: typeof Highcharts = Highcharts;
  incidentStatusChartData: Highcharts.Options | null = null;
  incidentDeviceModelChartData: Highcharts.Options | null = null;

  ngOnInit(): void {
    exporting(this.Highcharts);
  }

  ngOnChanges() {
    this.prepareChartsData();
  }

  prepareChartsData() {
    const incidentsCount = {
      resolved: 0,
      inQueue: 0,
      inProgress: 0,
    };

    const deviceModels: Array<{ id: string; modelName: string; manufacturerName: string; count: number }> = [];

    this.incidents?.forEach(incident => {
      if (incident.status === IncidentStatus.RESOLVED) {
        incidentsCount.resolved += 1;
      }
      if (incident.status === IncidentStatus.IN_PROGRESS) {
        incidentsCount.inProgress += 1;
      }
      if (incident.status === IncidentStatus.IN_QUEUE) {
        incidentsCount.inQueue += 1;
      }

      const deviceModel = deviceModels.find(
        deviceModel => deviceModel.id === incident.device.deviceModelInformation?.id
      );
      if (deviceModel) {
        deviceModel.count += 1;
      } else if (incident.device.deviceModelInformation) {
        deviceModels.push({
          id: incident.device.deviceModelInformation.id,
          modelName: incident.device.deviceModelInformation.name,
          manufacturerName: incident.device.deviceModelInformation.make.name,
          count: 1,
        });
      }
    });

    this.incidentStatusChartData = createIncidentStatusChart(incidentsCount);
    this.incidentDeviceModelChartData = createDeviceModelChart(deviceModels);
  }
}
