<div
  *ngIf="showFilters"
  class="test-filters-container border-b-ui-gray-light border-b-[1px] flex items-center justify-between mb-3">
  <mat-button-toggle-group
    class="desktop-toggle-group"
    name="site-status"
    aria-label="Room Status"
    [value]="roomStatusFilter"
    (change)="onRoomStatusFilterChange($event)">
    <mat-button-toggle value="current">
      <span class="item-status-title">Current</span>
    </mat-button-toggle>
    <mat-button-toggle value="archived">
      <span class="item-status-title">Archived</span>
    </mat-button-toggle>
  </mat-button-toggle-group>
  <app-search-input placeholder="Search room" (searchDataEvent)="roomSearch($event)"></app-search-input>
</div>
<p
  class="bg-ui-gray-50 font-nunito text-sm text-ui-gray-100 font-semibold h-8 flex px-3 mb-0 items-center border border-ui-gray-light">
  Rooms
</p>
<form
  [formGroup]="form"
  *ngIf="roomStatusFilter !== 'archived'"
  (ngSubmit)="submit()"
  class="flex px-3 py-2 w-full border-x border-b border-ui-gray-light">
  <mat-form-field class="input-classic size-small w-full no-error-message mr-3">
    <input formControlName="friendlyName" matInput placeholder="Enter Room name*" type="text" />
  </mat-form-field>
  <mat-form-field class="input-classic size-small w-full no-error-message mr-3">
    <input formControlName="externalReference" matInput placeholder="Enter doc link" type="text" />
  </mat-form-field>
  <button
    type="submit"
    [disabled]="!form.valid || (roomIsLoading$ | async) || !form.value.friendlyName.trim()"
    class="btn-green btn-small min-w-[116px]">
    <mat-icon *ngIf="(roomIsLoading$ | async) === false" fontIcon="add" />
    <mat-spinner *ngIf="roomIsLoading$ | async" diameter="20" class="mr-1" />
    Add Room
  </button>
</form>
<div *ngIf="floor.rooms?.length && locationId" class="overflow-auto max-h-[440px]">
  <app-room-item
    *ngFor="let room of filteredRooms"
    [locationId]="locationId"
    [room]="room"
    [floorplan]="floor.documentReference" />
</div>
