<div [class.mt-5]="devicesFilterService.isReportMode">
  <div
    *ngIf="!devicesFilterService.isReportMode"
    class="test-button-group border-b-ui-gray-light border-b flex justify-between items-center mb-5">
    <mat-button-toggle-group
      class="desktop-toggle-group"
      name="site-status"
      aria-label="Building Status"
      [value]="devicesFilterService.deviceStatusFilter"
      (change)="devicesFilterService.onDeviceStatusFilterChange($event)">
      <mat-button-toggle value="current" class="test-device-status-current">
        <span class="item-status-title">Current</span>
      </mat-button-toggle>
      <mat-button-toggle value="archived" class="test-device-status-archived">
        <span class="item-status-title">Archived</span>
      </mat-button-toggle>
    </mat-button-toggle-group>

    <div class="test-search-panel flex justify-center" *ngIf="devicesFilterService.filtersEnabled">
      <app-search-input
        placeholder="Device Name, type & other"
        (searchDataEvent)="devicesFilterService.deviceSearch($event)"
        class="mr-2" />
      <mat-form-field class="column-select">
        <mat-label>Column</mat-label>
        <mat-select
          panelClass="multi-select-overlay column-select-overlay"
          #columnSelectRef
          (selectionChange)="devicesFilterService.columnsChange($event)"
          [formControl]="devicesFilterService.columnSelect"
          multiple>
          <div class="options-action-wrapper">
            <button class="options-action-button" (click)="selectAllColumns()">Select All</button>
          </div>
          <mat-option *ngFor="let option of devicesFilterService.columnOptions" [value]="option.value">{{
            option.title
          }}</mat-option>
        </mat-select>
      </mat-form-field>

      <!-- <ng-container *ngIf="selectEnabled">
        <span appRestrictedEl [showForClientAdmin]="true" class="flex border-l border-l-ui-gray-light mx-2"></span>
        <button
          appRestrictedEl
          [showForClientAdmin]="true"
          class="device-action-button device-resume-button mr-2"
          [disabled]="!selection.selected.length"
          (click)="resumeDevices()">
          <i class="icon-play-16 text-3xl mr-2"></i>
          Resume
        </button>
        <button
          appRestrictedEl
          [showForClientAdmin]="true"
          class="device-action-button device-pause-button"
          [disabled]="!selection.selected.length"
          (click)="pauseDevices()">
          <i class="icon-pause-16 text-3xl mr-2"></i>
          Pause
        </button>
      </ng-container> -->
    </div>
  </div>

  <div
    *ngIf="devicesFilterService.filtersEnabled && devicesFilterService.normalizedDeviceList.length"
    class="flex items-start flex-wrap test-filters-panel">
    <app-multiple-select
      [value]="devicesFilterService.deviceTypeValue"
      [options]="devicesFilterService.deviceTypeOptions"
      label="Device Type"
      selectClass="stroked-select-multiple"
      panelClass="device-type-overlay"
      class="test-device-type-select mr-2"
      (selectDataEvent)="devicesFilterService.deviceTypeValue = $event" />

    <app-multiple-select
      [value]="devicesFilterService.manufacturerValue"
      [options]="devicesFilterService.manufacturerOptions"
      label="Manufacturer"
      selectClass="stroked-select-multiple"
      panelClass="assigned-user-overlay"
      class="test-manufacturer-select mr-2"
      (selectDataEvent)="devicesFilterService.manufacturerValue = $event" />

    <mat-form-field
      *ngIf="devicesFilterService.showLocationFilter"
      class="test-building-select stroked-select-single mr-2">
      <mat-label>Building</mat-label>
      <mat-select [(ngModel)]="devicesFilterService.selectedBuilding" panelClass="single-select-overlay">
        <mat-option
          *ngFor="let building of devicesFilterService.buildingOptions | sort : 'asc' : 'title' : 'all'"
          [value]="building.value">
          {{ building.title }}
        </mat-option>
      </mat-select>
      <mat-icon matSuffix class="arrow-down">keyboard_arrow_down</mat-icon>
      <mat-icon matSuffix class="arrow-up">keyboard_arrow_up</mat-icon>
    </mat-form-field>

    <mat-form-field class="test-floor-select stroked-select-single mr-2" *ngIf="devicesFilterService.selectedBuilding">
      <mat-label>Floor</mat-label>
      <mat-select [(ngModel)]="devicesFilterService.selectedFloor" panelClass="single-select-overlay">
        <mat-option
          *ngFor="let floor of devicesFilterService.floorOptions | sort : 'asc' : 'title' : 'all'"
          [value]="floor.value">
          {{ floor.title }}
        </mat-option>
      </mat-select>
      <mat-icon matSuffix class="arrow-down">keyboard_arrow_down</mat-icon>
      <mat-icon matSuffix class="arrow-up">keyboard_arrow_up</mat-icon>
    </mat-form-field>

    <mat-form-field class="test-room-select stroked-select-single mr-2" *ngIf="devicesFilterService.selectedFloor">
      <mat-label>Room</mat-label>
      <mat-select
        [(ngModel)]="devicesFilterService.selectedRoom"
        panelClass="single-select-overlay device-room-filter-overlay">
        <mat-option
          *ngFor="let room of devicesFilterService.roomOptions | sort : 'asc' : 'title' : 'all'"
          [value]="room.value">
          {{ room.title }}
        </mat-option>
      </mat-select>
      <mat-icon matSuffix class="arrow-down">keyboard_arrow_down</mat-icon>
      <mat-icon matSuffix class="arrow-up">keyboard_arrow_up</mat-icon>
    </mat-form-field>

    <button
      class="test-clear-all-filters font-nunito text-sm text-ui-dark font-normal underline h-9 ml-3 pt-2"
      (click)="devicesFilterService.clearAllFilters()"
      *ngIf="devicesFilterService.selectedFilters">
      Clear All
    </button>
  </div>
</div>
