<div *ngIf="incidents">
  <div class="grid grid-cols-2 gap-4 my-4">
    <mat-card class="card p-0" *ngIf="incidentStatusChartData">
      <highcharts-chart
        [Highcharts]="Highcharts"
        [options]="incidentStatusChartData"
        class="incident-status-chart highcharts-small-title" />
    </mat-card>
    <mat-card class="card p-0" *ngIf="incidentDeviceModelChartData">
      <div class="h-[400px] overflow-y-auto">
        <highcharts-chart
          [Highcharts]="Highcharts"
          [options]="incidentDeviceModelChartData"
          class="highcharts-classic-axes block highcharts-small-title" />
      </div>
    </mat-card>
  </div>
</div>
