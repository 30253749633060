import { Component, Input, ViewChild } from '@angular/core';
import { MatFormField, MatOption, MatSelect, MatSuffix } from '@angular/material/select';
import { IncidentTabStatusFilter } from '@models';
import { IncidentsFilterService } from '@services';
import { NgForOf, NgIf } from '@angular/common';
import { MatButtonToggle, MatButtonToggleGroup } from '@angular/material/button-toggle';
import { SearchInputComponent } from '@standalone/search-input/search-input.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MultipleSelectComponent } from '@standalone/multiple-select/multiple-select.component';
import {
  MatDatepickerToggle,
  MatDateRangeInput,
  MatDateRangePicker,
  MatEndDate,
  MatStartDate,
} from '@angular/material/datepicker';
import { PipesModule } from '@pipes';
import { MatIcon } from '@angular/material/icon';
import { MatProgressSpinner } from '@angular/material/progress-spinner';
import { MatButton } from '@angular/material/button';
import { MatLabel } from '@angular/material/form-field';

@Component({
  standalone: true,
  selector: 'app-incidents-table-desktop-filters',
  templateUrl: './incidents-table-desktop-filters.component.html',
  imports: [
    NgIf,
    MatButtonToggleGroup,
    MatButtonToggle,
    SearchInputComponent,
    MatFormField,
    MatSelect,
    ReactiveFormsModule,
    MatOption,
    MultipleSelectComponent,
    MatDateRangeInput,
    MatStartDate,
    MatEndDate,
    MatDatepickerToggle,
    MatDateRangePicker,
    FormsModule,
    PipesModule,
    NgForOf,
    MatIcon,
    MatProgressSpinner,
    MatSuffix,
    MatLabel,
    MatButton,
  ],
})
export class IncidentsTableDesktopFiltersComponent {
  @Input() filtersEnabled = false;
  @Input() dropdownFilters = true;
  @Input() resolvedIncidentsLoading = false;
  @ViewChild('columnSelectRef') columnSelectRef!: MatSelect;

  incidentTabStatusFilterEnum = IncidentTabStatusFilter;

  constructor(public incidentsFilterService: IncidentsFilterService) {}

  selectAllColumns() {
    this.columnSelectRef.options.forEach((item: MatOption) => item.select());
  }
}
