@if (dataSource.data.length) {
<div class="flex justify-between items-center mb-4">
  <app-page-header>Client Management</app-page-header>
  <a class="btn-green btn-medium" routerLink="add-client">
    <mat-icon fontIcon="add" />
    Add New Client</a
  >
</div>
<mat-card class="card py-0">
  <mat-card-content class="p-0">
    <div class="mb-3">
      <div class="border-b-ui-gray-light border-b-[1px] flex items-center justify-between">
        <mat-button-toggle-group
          class="desktop-toggle-group"
          name="client-status"
          aria-label="Client Status"
          (valueChange)="onStatusFilterChange($event)"
          [value]="clientStatusFilter">
          <mat-button-toggle value="current" class="test-client-status-current">
            <span class="item-status-title">Current</span>
          </mat-button-toggle>
          <mat-button-toggle value="archived" class="test-client-status-archived">
            <span class="item-status-title">Archived</span>
          </mat-button-toggle>
        </mat-button-toggle-group>
        <app-search-input placeholder="Enter Client Name" (searchDataEvent)="clientSearch($event)" />
      </div>
    </div>

    <div class="overflow-auto">
      <table
        matSortActive="friendlyName"
        matSortDirection="asc"
        mat-table
        [dataSource]="dataSource"
        class="main-table"
        id="clients-table"
        matSort>
        <!-- Number Column -->
        <ng-container matColumnDef="index">
          <th mat-header-cell *matHeaderCellDef>№</th>
          <td mat-cell *matCellDef="let index = index">
            {{ paginatorBlock.pageIndex * paginatorBlock.pageSize + (index + 1) }}
          </td>
        </ng-container>

        <!-- Client Name Column -->
        <ng-container matColumnDef="friendlyName">
          <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by client name">
            Client Name
          </th>
          <td mat-cell *matCellDef="let element">
            <a class="underline cursor-pointer" (click)="selectClient(element.id)">{{ element.friendlyName }}</a>
          </td>
        </ng-container>

        <!-- Status Column -->
        <ng-container matColumnDef="status">
          <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by status">Status</th>
          <td mat-cell *matCellDef="let element">
            <app-user-status [status]="element.status" />
          </td>
        </ng-container>

        <!-- Date Created Column -->
        <ng-container matColumnDef="created">
          <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by date created">
            Date Created
          </th>
          <td mat-cell *matCellDef="let element">{{ element.createdOn | date : 'MM/dd/yy - hh:mm a' }}</td>
        </ng-container>

        <!-- Action Column -->
        <ng-container matColumnDef="actions">
          <th mat-header-cell *matHeaderCellDef></th>
          <td mat-cell *matCellDef="let element">
            @if (!element.isManagingClient) {
            <div class="flex items-center">
              @if (element.status === UserStatus.ARCHIVED) {
              <i
                class="icon-restore action-icon"
                *ngIf="element.status === UserStatus.ARCHIVED"
                (click)="unarchiveClient(element)"
                matTooltip="Restore"></i>
              } @else {
              <i class="icon-edit action-icon" matTooltip="Edit" (click)="selectClient(element.id)"></i>
              @if (element.status === UserStatus.DEACTIVATED) {
              <i class="icon-active action-icon" (click)="activateClient(element)" matTooltip="Activate"></i>
              } @else {
              <i class="icon-Deactivate action-icon" (click)="deactivateClient(element)" matTooltip="Deactivate"></i>
              }
              <i
                class="icon-add-to-archive action-icon"
                *ngIf="element.status !== UserStatus.ARCHIVED"
                (click)="archiveClient(element)"
                matTooltip="Archive"></i>
              }
            </div>
            }
          </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns" [class.is-deactivated]="false"></tr>
      </table>
    </div>

    <app-no-data *ngIf="!dataSource.filteredData.length" [resultContent]="!!dataSource.data.length" />

    <mat-paginator
      #paginatorBlock
      [ngClass]="dataSource.filteredData.length ? 'visible' : 'invisible'"
      appPaginatorSettings
      elementToScrollId="clients-table"
      aria-label="Items per page:" />
  </mat-card-content>
</mat-card>
} @else {
<app-skeleton-table />
}
