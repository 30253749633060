<app-page-header>Reporting</app-page-header>

<div class="flex items-center justify-between mt-4">
  <mat-card-title class="card-title-small">Generate Report</mat-card-title>
  <app-export-report-menu
    *ngIf="reportingType === 'firmware'"
    [disabled]="!devicesFilterService.filteredDevices.length"
    (exportDataType)="exportDevices($event)" />
  <app-export-report-menu
    *ngIf="reportingType === 'incident'"
    [disabled]="!incidents?.length"
    (exportDataType)="exportIncidents($event)" />
</div>

<mat-card class="bg-white card mt-4">
  <mat-card-content class="p-0">
    <app-report-filters
      [reportingType]="reportingType"
      (reportTypeEvent)="reportingType = $event"
      (exportIncidents)="setIncidents($event)"></app-report-filters>
  </mat-card-content>
</mat-card>

<app-incident-charts *ngIf="reportingType === 'incident' && incidents" [incidents]="incidents" />

<mat-card class="card py-0" *ngIf="reportingType === 'incident' && incidents">
  <mat-card-title class="card-title-small test-table-title border-b-ui-gray-light border-b py-3.5 mb-3"
    >Incident List
  </mat-card-title>
  <mat-card-content class="p-0">
    <app-incidents-table [incidentsData]="incidents" [isReportMode]="true" />
  </mat-card-content>
</mat-card>

<ng-container *ngIf="reportingType === 'firmware'">
  <mat-card class="card pt-0 mt-4">
    <mat-card-title class="card-title-small test-table-title border-b-ui-gray-light border-b py-3.5"
      >Firmware List
    </mat-card-title>
    <mat-card-content *ngIf="deviceListLoaded$ | async; else spinner" class="p-0">
      <app-devices-table-desktop-filters />
      <app-devices-table />
    </mat-card-content>
  </mat-card>
  <ng-template #spinner>
    <app-skeleton-table />
  </ng-template>
</ng-container>

<app-incidents-monthly-chart *ngIf="reportingType === 'incident'" />
