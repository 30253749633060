<div class="min-h-full flex max-h-full" *ngIf="isLoggedIn; else loggedOut">
  <div class="sidebar-menu bg-ui-primary overflow-visible max-w-[260px] flex">
    <app-main-menu />
  </div>
  <main #main class="flex flex-grow flex-col bg-ui-gray-200 w-full max-w-full max-h-full overflow-y-auto">
    <section class="p-6 pt-4 w-full">
      <router-outlet />
    </section>
  </main>
</div>
<ng-template #loggedOut>
  <div class="h-screen overflow-y-auto flex flex-col">
    <div class="flex-1">
      <router-outlet />
    </div>
    <app-copyright-footer />
  </div>
</ng-template>
