<div class="flex items-end">
  <div class="flex flex-col mr-6">
    <span class="flex font-nunito text-sm font-semibold text-ui-gray-100 mb-1">Reporting by:</span>
    <mat-button-toggle-group
      [(ngModel)]="reportingType"
      (change)="changePeriod($event)"
      class="main-toggle-group"
      aria-label="type of reports">
      <mat-button-toggle class="border-ui-green-light" value="incident">
        <mat-icon matIconPrefix *ngIf="reportingType === 'incident'">check</mat-icon>
        Incident
      </mat-button-toggle>
      <mat-button-toggle class="border-ui-green-light" value="firmware">
        <mat-icon matIconPrefix *ngIf="reportingType === 'firmware'">check</mat-icon>
        Firmware
      </mat-button-toggle>
    </mat-button-toggle-group>
  </div>
  <ng-container *ngIf="reportingType === 'incident'">
    <div class="flex flex-col mr-6">
      <span class="flex font-nunito text-sm font-semibold text-ui-gray-100 mb-1">Select report period:*</span>
      <mat-form-field class="date-range-picker no-validation-errors middle-height" (click)="picker.open()">
        <mat-label>Enter report period</mat-label>
        <mat-date-range-input [rangePicker]="picker" [formGroup]="resolvedDateRange">
          <input [readonly]="true" matStartDate formControlName="start" placeholder="mm/dd/yyyy" />
          <input [readonly]="true" matEndDate formControlName="end" placeholder="mm/dd/yyyy" />
        </mat-date-range-input>
        <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-date-range-picker #picker></mat-date-range-picker>
      </mat-form-field>
    </div>
    <button
      class="test-generate-report-btn btn-yellow btn-medium generate-report-btn mr-10"
      [disabled]="!(this.resolvedDateRange.value.start && this.resolvedDateRange.value.end) || resolvedIncidentsLoading"
      (click)="generateReport()">
      <mat-spinner *ngIf="resolvedIncidentsLoading" diameter="20" class="mr-1"></mat-spinner>
      Generate Report
    </button>
    <button class="test-reset font-nunito h-10 font-semibold text-base text-ui-dark underline" (click)="reset()">
      Reset
    </button>
  </ng-container>
</div>
<div
  *ngIf="commonIncidents?.length && reportingType === 'incident'"
  class="test-filters-wrapper border-b-ui-gray-light border-t pt-6 mt-6">
  <div class="test-location-item flex flex-row items-center mb-5" *ngFor="let location of locations; let i = index">
    <label class="input-main-label mt-0">Building:</label>
    <div class="flex">
      <mat-form-field class="select-classic mr-3">
        <mat-select
          [value]="location.building"
          (selectionChange)="buildingChange($event, i)"
          class="test-building-select"
          placeholder="Select Building"
          panelClass="single-select-overlay">
          <mat-option
            *ngFor="let building of !i ? allBuildingOptions : (defaultBuildingOptions | sort : 'asc' : 'title' : 'all')"
            [value]="building.value">
            {{ building.title }}
          </mat-option>
        </mat-select>
        <mat-icon matSuffix class="arrow-down">keyboard_arrow_down</mat-icon>
        <mat-icon matSuffix class="arrow-up">keyboard_arrow_up</mat-icon>
      </mat-form-field>

      <mat-form-field *ngIf="location.building" class="select-classic w-[120px] mr-3">
        <mat-select
          [value]="location.floor"
          (selectionChange)="floorChange($event, i)"
          placeholder="Floor"
          panelClass="single-select-overlay">
          <mat-option *ngFor="let floor of location.floorOptions" [value]="floor.value">
            {{ floor.title }}
          </mat-option>
        </mat-select>
        <mat-icon matSuffix class="arrow-down">keyboard_arrow_down</mat-icon>
        <mat-icon matSuffix class="arrow-up">keyboard_arrow_up</mat-icon>
      </mat-form-field>

      <app-multiple-select
        *ngIf="location.floor"
        [value]="location.rooms"
        class="mr-3"
        [options]="location.roomOptions"
        label="Room"
        panelClass="room-overlay"
        [showLabelPlaceholder]="false"
        selectClass="stroked-select-multiple-classic classic-form-item-wrapper w-[120px] mb-0"
        (selectDataEvent)="roomChange($event, i)"></app-multiple-select>

      <button
        *ngIf="i === locations.length - 1"
        class="mr-3 test-add-locaiton btn-green btn-medium w-10 px-0"
        (click)="addLocation()">
        <mat-icon>add</mat-icon>
      </button>
      <button
        *ngIf="locations.length > 1"
        class="test-remove-location btn-red btn-medium w-10 px-0"
        (click)="deleteLocation(i)">
        <i class="icon-remove text-3xl"></i>
      </button>
    </div>
  </div>
  <div class="flex flex-row items-center mb-5">
    <label class="input-main-label mt-0">Device type:</label>
    <div>
      <app-multiple-select
        [value]="deviceTypeValue"
        [options]="deviceTypeOptions"
        label="All"
        [showLabelPlaceholder]="false"
        selectClass="stroked-select-multiple-classic classic-form-item-wrapper mb-0"
        selectElementClass="test-device-select"
        (selectDataEvent)="multiSelectChange('deviceTypeValue', $event)"></app-multiple-select>
    </div>
  </div>
  <div class="flex flex-row items-center mb-5">
    <label class="input-main-label mt-0">Incident status:</label>
    <app-multiple-select
      [value]="incidentStatusValue"
      [options]="incidentStatusOptions"
      label="All"
      [showLabelPlaceholder]="false"
      selectClass="stroked-select-multiple-classic classic-form-item-wrapper mb-0"
      selectElementClass="test-incident-select"
      (selectDataEvent)="multiSelectChange('incidentStatusValue', $event)"></app-multiple-select>
  </div>
  <!-- Incident filter controls-->
  <!-- <div *ngIf="reportingType === 'incident'" class="flex flex-row items-center mb-5">
    <label class="input-main-label mt-0">Assigned User:</label>
    <div>
      <app-multiple-select
        [value]="assignedUserValue"
        [options]="assignedUserOptions"
        label="All"
        [showLabelPlaceholder]="false"
        selectClass="test-assign-user-select stroked-select-multiple-classic classic-form-item-wrapper mb-0"
        (selectDataEvent)="multiSelectChange('assignedUserValue', $event)"></app-multiple-select>
    </div>
  </div> -->
</div>

<!-- Firmware filter controls-->
<!-- <div *ngIf="reportingType === 'firmware'" class="flex flex-row items-center mb-5">
  <label class="input-main-label mt-0">Manufacturer:</label>
  <div>
    <app-multiple-select
      [value]="manufacturerValue"
      [options]="manufacturerOptions"
      label="All"
      [showLabelPlaceholder]="false"
      selectClass="test-manufacturer-select stroked-select-multiple-classic classic-form-item-wrapper mb-0"
      (selectDataEvent)="multiSelectChange('manufacturerValue', $event)"></app-multiple-select>
  </div>
</div>
<div *ngIf="reportingType === 'firmware'" class="flex flex-row items-center mb-5">
  <label class="input-main-label mt-0">Model:</label>
  <div>
    <app-multiple-select
      [value]="modelValue"
      [options]="modelOptions"
      label="All"
      [showLabelPlaceholder]="false"
      selectClass="test-model-select stroked-select-multiple-classic classic-form-item-wrapper mb-0"
      (selectDataEvent)="multiSelectChange('modelValue', $event)"></app-multiple-select>
  </div>
</div>
<div *ngIf="reportingType === 'firmware'" class="flex flex-row items-center mb-5">
  <label class="input-main-label mt-0">Firmware:</label>
  <div>
    <app-multiple-select
      [value]="firmwareValue"
      [options]="firmwareOptions"
      label="All"
      [showLabelPlaceholder]="false"
      selectClass="test-firmware-select stroked-select-multiple-classic classic-form-item-wrapper mb-0"
      (selectDataEvent)="multiSelectChange('firmwareValue', $event)"></app-multiple-select>
  </div>
</div> -->
