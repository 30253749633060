<mat-card class="card mx-auto mt-4 px-3">
  <div class="flex mb-4 mx-3">
    <mat-button-toggle-group
      [value]="periodFilterValue"
      class="main-toggle-group"
      name="period"
      [disabled]="isLoading"
      (change)="changePeriod($event)"
      aria-label="period of time">
      <mat-button-toggle class="border-ui-green-light" [value]="ReportYear.CURRENT">
        <mat-icon matIconPrefix *ngIf="periodFilterValue === ReportYear.CURRENT">check</mat-icon>
        Current Year
      </mat-button-toggle>
      <mat-button-toggle class="border-ui-green-light" [value]="ReportYear.PREVIOUS">
        <mat-icon matIconPrefix *ngIf="periodFilterValue === ReportYear.PREVIOUS">check</mat-icon>
        Previous Year
      </mat-button-toggle>
    </mat-button-toggle-group>
    <mat-spinner matSuffix *ngIf="isLoading" class="ml-2" diameter="40" color="primary"></mat-spinner>
  </div>
  @if (!isLoading) {
  <highcharts-chart
    *ngIf="incidentMonthChartData"
    class="highcharts-no-legend highcharts-small-title highcharts-classic-axes"
    [Highcharts]="Highcharts"
    [options]="incidentMonthChartData" />
  } @else {
  <ngx-skeleton-loader [theme]="{ height: '400px' }" />
  }

  <div *ngIf="!incidentMonthChartData" class="h-[400px]"></div>
</mat-card>
