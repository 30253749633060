<mat-card-header *ngIf="!isMobile" class="p-0 flex justify-between items-center">
  <mat-card-title class="card-title">Rooms</mat-card-title>
  <a
    appRestrictedEl
    [routerLink]="['/dashboard', locationId, floorId, 'edit']"
    class="btn-base border border-ui-gray-150 px-2 py-1 text-ui-gray-250 text-sm font-normal cursor-pointer">
    <mat-icon matPrefix>add</mat-icon>
    Add Room
  </a>
</mat-card-header>
<mat-card-content class="p-0 overflow-x-auto">
  <div class="flex justify-between mb-4 border-b">
    <mat-button-toggle-group
      *ngIf="!isMobile"
      class="desktop-toggle-group"
      name="incident-status"
      aria-label="Incident Status"
      (change)="onStatusFilterChange($event)"
      [value]="statusFilter">
      <mat-button-toggle value="open" class="test-incident-status-open">
        <span class="item-status-title">Current</span>
      </mat-button-toggle>
      <mat-button-toggle value="history" class="test-incident-status-history">
        <span class="item-status-title">Archived</span>
      </mat-button-toggle>
    </mat-button-toggle-group>
    <div class="flex items-center" [class.w-full]="isMobile">
      <app-search-input
        [inputWidth]="isMobile ? '100%' : '256px'"
        [class.w-full]="isMobile"
        placeholder="Room Name"
        (searchDataEvent)="tableSearch($event)" />
    </div>
  </div>
  <div class="overflow-x-auto">
    <table
      matSort
      matSortActive="friendlyName"
      matSortDirection="asc"
      mat-table
      [dataSource]="dataSource"
      id="rooms-table"
      class="test-room-table main-table base-table pt-4">
      <ng-container class="sticky" matColumnDef="position">
        <th mat-header-cell *matHeaderCellDef>№</th>
        <td mat-cell *matCellDef="let index = index">
          {{ paginatorRef.pageIndex * paginatorRef.pageSize + (index + 1) }}
        </td>
      </ng-container>

      <ng-container matColumnDef="room">
        <th
          mat-sort-header="friendlyName"
          mat-header-cell
          [class]="isMobile ? 'min-w-[180px]' : 'min-w-[250px]'"
          *matHeaderCellDef>
          Rooms
        </th>
        <td mat-cell *matCellDef="let element">
          <a class="underline" routerLink="{{ element.id }}">{{ element.friendlyName }}</a>
        </td>
      </ng-container>

      <ng-container matColumnDef="status">
        <th mat-sort-header="status" mat-header-cell *matHeaderCellDef>Status</th>
        <td mat-cell *matCellDef="let element">
          <app-entity-status [status]="element.status" />
        </td>
      </ng-container>

      <ng-container matColumnDef="incidents">
        <th mat-sort-header="incidents" mat-header-cell *matHeaderCellDef>Incidents</th>
        <td mat-cell *matCellDef="let element">
          <ng-container *ngIf="element.status === entityStatus.Active">
            <app-incident-status-count
              *ngIf="element.incidentCountByStatuses?.newCount"
              [status]="incidentStatus.IN_QUEUE"
              [amount]="element.incidentCountByStatuses.newCount"
              class="mr-1" />
            <app-incident-status-count
              *ngIf="element.incidentCountByStatuses?.inProgressCount"
              [status]="incidentStatus.IN_PROGRESS"
              [amount]="element.incidentCountByStatuses.inProgressCount" />
          </ng-container>
        </td>
      </ng-container>

      <ng-container matColumnDef="devices">
        <th mat-sort-header="devices" mat-header-cell *matHeaderCellDef>Devices</th>
        <td mat-cell *matCellDef="let element">
          <ng-container *ngIf="element.status === entityStatus.Active">
            <app-device-status-count
              *ngIf="element.devicesByStatuses"
              [activeAmount]="element.devicesByStatuses.activeCount"
              [pausedAmount]="element.devicesByStatuses.pausedCount" />
          </ng-container>
        </td>
      </ng-container>

      <ng-container matColumnDef="actions">
        <th
          mat-header-cell
          [class]="isMobile ? 'min-w-[100px]' : 'min-w-[240px]'"
          *matHeaderCellDef
          [class.hidden]="!showActionColumn"></th>
        <td
          class="text-ui-gray-250 text-right whitespace-nowrap"
          [class.hidden]="!showActionColumn"
          mat-cell
          *matCellDef="let element">
          <ng-container *ngIf="element.status !== entityStatus.Archived">
            <a
              *ngIf="element.externalReference"
              [href]="element.externalReference"
              target="_blank"
              [matTooltip]="'Doc link'"
              class="inline-flex items-center action-icon mx-1">
              <i class="icon-link text-3xl"></i>
            </a>
            <a
              matTooltip="Edit"
              class="mx-1 cursor-pointer action-icon"
              appRestrictedEl
              [showForClientAdmin]="true"
              (click)="editFloor(element)">
              <i class="icon-edit text-3xl"></i>
            </a>
            <button
              class="mx-1 cursor-pointer action-icon"
              appRestrictedEl
              [showForClientAdmin]="true"
              *ngIf="element.status === entityStatus.Paused"
              (click)="updateRoomStatus(element, 'Resume', entityStatus.Active)">
              <i class="icon-play-16 text-3xl"></i>
            </button>
            <button
              matTooltip="Pause"
              class="mx-1 cursor-pointer action-icon"
              appRestrictedEl
              [showForClientAdmin]="true"
              *ngIf="element.status === entityStatus.Active"
              (click)="updateRoomStatus(element, 'Pause', entityStatus.Paused)">
              <i class="icon-pause-16 text-3xl"></i>
            </button>
            <button
              matTooltip="Archive"
              class="mx-1 cursor-pointer action-icon"
              *ngIf="!isMobile"
              (click)="updateRoomStatus(element, 'Add to Archive', entityStatus.Archived)"
              appRestrictedEl>
              <i class="icon-add-to-archive text-3xl"></i>
            </button>
          </ng-container>
          <button
            matTooltip="Restore"
            appRestrictedEl
            class="mx-1 cursor-pointer"
            *ngIf="element.status === entityStatus.Archived && !isMobile"
            (click)="updateRoomStatus(element, 'Restore', entityStatus.Active)">
            <i class="icon-restore text-3xl action-icon"></i>
          </button>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>
    <app-no-data
      *ngIf="!dataSource.filteredData.length"
      [resultContent]="!!dataSource.data.length"
      noDataText="No rooms yet"></app-no-data>
  </div>
</mat-card-content>
<mat-paginator
  [ngClass]="dataSource.filteredData.length ? 'visible' : 'invisible'"
  appPaginatorSettings
  elementToScrollId="rooms-table"
  #paginatorRef
  aria-label="Items per page:" />
