<div class="flex justify-between items-center mb-4">
  <app-page-header>Devices</app-page-header>
  <div class="flex">
    <app-export-report-menu class="mr-3" (exportDataType)="exportDevices($event)" />
    <a appRestrictedEl class="btn-yellow btn-medium" routerLink="/devices/add-device">
      <mat-icon>add</mat-icon>
      Add Device</a
    >
  </div>
</div>

<mat-card class="card pt-0">
  <mat-card-content *ngIf="deviceListLoaded$ | async; else spinner" class="test-content p-0">
    <app-devices-table-desktop-filters />
    <app-devices-table [isNoDataBigSize]="true" [selectEnabled]="false"></app-devices-table>
  </mat-card-content>
</mat-card>
<ng-template #spinner>
  <app-skeleton-table />
</ng-template>
