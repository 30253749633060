import { Component, DestroyRef, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatButtonToggleChange } from '@angular/material/button-toggle';
import { createMonthlyChart, getMonthsData } from '@app-lib';
import { IncidentModel } from '@models';
import { AppState, selectAllIncidents } from '@ngrx-store';
import { Store } from '@ngrx/store';
import { AppService, IncidentsService } from '@services';
import * as Highcharts from 'highcharts';
import exporting from 'highcharts/modules/exporting';

export enum ReportYear {
  CURRENT = 'current',
  PREVIOUS = 'previous',
}

@Component({
  selector: 'app-incidents-monthly-chart',
  templateUrl: './incidents-monthly-chart.component.html',
  styles: [],
})
export class IncidentsMonthlyChartComponent {
  Highcharts: typeof Highcharts = Highcharts;
  incidentMonthChartData: Highcharts.Options | null = null;
  openIncidents: IncidentModel[] = [];
  resolvedIncidents: IncidentModel[] = [];
  selectedYear = new Date().getFullYear();
  periodFilterValue: ReportYear = ReportYear.CURRENT;
  ReportYear = ReportYear;
  isLoading = false;
  destroyRef = inject(DestroyRef);

  constructor(
    private store: Store<AppState>,
    private incidentsService: IncidentsService,
    private appService: AppService
  ) {
    exporting(this.Highcharts);
    this.store
      .select(selectAllIncidents)
      .pipe(takeUntilDestroyed())
      .subscribe(openIncidents => {
        this.openIncidents = openIncidents;
        this.generateChartData();
      });

    this.getResolvedIncidents();
  }

  getDateRange() {
    let start = new Date();
    let end = new Date();

    if (this.periodFilterValue === ReportYear.CURRENT) {
      start = new Date(this.selectedYear, 0, 1);
    }

    if (this.periodFilterValue === ReportYear.PREVIOUS) {
      start = new Date(this.selectedYear, 0, 1);
      end = new Date(this.selectedYear, 11, 31, 23, 59, 59, 999);
    }

    return { startDateTime: start.toISOString(), endDateTime: end.toISOString() };
  }

  getResolvedIncidents() {
    this.isLoading = true;

    const { startDateTime, endDateTime } = this.getDateRange();

    this.incidentsService
      .getAllResolvedIncidents(this.appService.currentClient, {
        startDateTime,
        endDateTime,
      })
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: data => {
          this.resolvedIncidents = data;
          this.isLoading = false;
          this.generateChartData();
        },
        error: () => {
          this.isLoading = false;
        },
      });
  }

  generateChartData() {
    const monthsData = getMonthsData(this.selectedYear);
    const allIncidents = [...this.openIncidents, ...this.resolvedIncidents];
    const incidentsCountByMonth = monthsData.map(({ startDate, endDate }) => {
      let count = 0;
      const startMonth = new Date(startDate).getTime();
      const endMonth = new Date(endDate).getTime();

      allIncidents.forEach(incident => {
        const incidentDate = new Date(incident.resolvedTimestamp || incident.createdTimestamp).getTime();

        if (incidentDate >= startMonth && incidentDate <= endMonth) {
          count += 1;
        }
      });

      return count;
    });
    this.incidentMonthChartData = createMonthlyChart(incidentsCountByMonth, this.selectedYear);
  }

  changePeriod(event: MatButtonToggleChange) {
    this.periodFilterValue = event.value;
    this.selectedYear =
      this.periodFilterValue === ReportYear.CURRENT ? new Date().getFullYear() : new Date().getFullYear() - 1;
    this.getResolvedIncidents();
  }
}
